import { gql, useApolloClient } from "@apollo/client";
import { navigate } from 'gatsby';
import jwt_decode from 'jwt-decode';
import { useEffect } from "react";
import useActions from "../app/hooks";
import { Token } from "../types/auth";


const ACCOUNT_QUERY = gql`
    query GetAccount {
        account @client{
        userId
        name
        email
        phone
        isAuthenticated
    }
}`;

const CallbackPage = ({location}:any) => {
    const globalState = useApolloClient()
    const actions = useActions();

    if(typeof window !== `undefined`) {
        var url = new URL(location.href.replace("callback/#", "callback/?").replace("callback#","callback?"));
        const token = url.searchParams.get('id_token') || '';
        window.localStorage.setItem('idToken', token);
        const tokenDecoded = jwt_decode(token) as Token
        if(tokenDecoded == null) { return }

        const update = {
            account: {
                __typename: 'account',
                userId: (tokenDecoded['email']) || 'guest@navability.io',
                name: (tokenDecoded['given_name']) || 'Guest',
                email: (tokenDecoded['email']) || 'guest@navability.io',
                phone: (tokenDecoded['phone_number']) || '',
                isAuthenticated: tokenDecoded && true
            }
        }
        globalState.writeQuery({
            query: ACCOUNT_QUERY,
            data: update
        })
    }

    useEffect(() => {navigate('/')},[])

    return null
}

export default CallbackPage
